const NEIGHBORHOODS = [
    { id: 'adams-normandie', name: 'Adams-Normandie' },
    { id: 'arleta', name: 'Arleta' },
    { id: 'arlington-heights', name: 'Arlington Heights' },
    { id: 'atwater-village', name: 'Atwater Village' },
    { id: 'baldwin-hillscrenshaw', name: 'Baldwin Hills/Crenshaw' },
    { id: 'bel-air', name: 'Bel Air' },
    { id: 'beverly-crest', name: 'Beverly Crest' },
    { id: 'beverly-grove', name: 'Beverly Grove' },
    { id: 'beverlywood', name: 'Beverlywood' },
    { id: 'boyle-heights', name: 'Boyle Heights' },
    { id: 'brentwood', name: 'Brentwood' },
    { id: 'broadway-manchester', name: 'Broadway-Manchester' },
    { id: 'canoga-park', name: 'Canoga Park' },
    { id: 'carthay', name: 'Carthay' },
    { id: 'central-alameda', name: 'Central Alameda' },
    { id: 'century-city', name: 'Century City' },
    { id: 'chatsworth', name: 'Chatsworth' },
    { id: 'chesterfield-square', name: 'Chesterfield Square' },
    { id: 'cheviot-hills', name: 'Cheviot Hills' },
    { id: 'chinatown', name: 'Chinatown' },
    { id: 'cypress-park', name: 'Cypress Park' },
    { id: 'del-rey', name: 'Del Rey' },
    { id: 'downtown', name: 'Downtown' },
    { id: 'eagle-rock', name: 'Eagle Rock' },
    { id: 'east-hollywood', name: 'East Hollywood' },
    { id: 'echo-park', name: 'Echo Park' },
    { id: 'el-sereno', name: 'El Sereno' },
    { id: 'elysian-park', name: 'Elysian Park' },
    { id: 'elysian-valley', name: 'Elysian Valley' },
    { id: 'encino', name: 'Encino' },
    { id: 'exposition-park', name: 'Exposition Park' },
    { id: 'fairfax', name: 'Fairfax' },
    { id: 'florence', name: 'Florence' },
    { id: 'glassell-park', name: 'Glassell Park' },
    { id: 'gramercy-park', name: 'Gramercy Park' },
    { id: 'granada-hills', name: 'Granada Hills' },
    { id: 'green-meadows', name: 'Green Meadows' },
    { id: 'hancock-park', name: 'Hancock Park' },
    { id: 'harbor-city', name: 'Harbor City' },
    { id: 'harbor-gateway', name: 'Harbor Gateway' },
    { id: 'harvard-heights', name: 'Harvard Heights' },
    { id: 'harvard-park', name: 'Harvard Park' },
    { id: 'highland-park', name: 'Highland Park' },
    { id: 'historic-south-central', name: 'Historic South Central' },
    { id: 'hollywood', name: 'Hollywood' },
    { id: 'hollywood-hills', name: 'Hollywood Hills' },
    { id: 'hollywood-hills-west', name: 'Hollywood Hills West' },
    { id: 'hyde-park', name: 'Hyde Park' },
    { id: 'jefferson-park', name: 'Jefferson Park' },
    { id: 'koreatown', name: 'Koreatown' },
    { id: 'lake-balboa', name: 'Lake Balboa' },
    { id: 'lake-view-terrace', name: 'Lake View Terrace' },
    { id: 'larchmont', name: 'Larchmont' },
    { id: 'leimert-park', name: 'Leimert Park' },
    { id: 'lincoln-heights', name: 'Lincoln Heights' },
    { id: 'los-feliz', name: 'Los Feliz' },
    { id: 'manchester-square', name: 'Manchester Square' },
    { id: 'mar-vista', name: 'Mar Vista' },
    { id: 'mid-city', name: 'Mid-City' },
    { id: 'mid-wilshire', name: 'Mid-Wilshire' },
    { id: 'mission-hills', name: 'Mission Hills' },
    { id: 'montecito-heights', name: 'Montecito Heights' },
    { id: 'mount-washington', name: 'Mount Washington' },
    { id: 'north-hills', name: 'North Hills' },
    { id: 'north-hollywood', name: 'North Hollywood' },
    { id: 'northridge', name: 'Northridge' },
    { id: 'pacific-palisades', name: 'Pacific Palisades' },
    { id: 'pacoima', name: 'Pacoima' },
    { id: 'palms', name: 'Palms' },
    { id: 'panorama-city', name: 'Panorama City' },
    { id: 'pico-robertson', name: 'Pico-Robertson' },
    { id: 'pico-union', name: 'Pico-Union' },
    { id: 'playa-del-rey', name: 'Playa del Rey' },
    { id: 'playa-vista', name: 'Playa Vista' },
    { id: 'porter-ranch', name: 'Porter Ranch' },
    { id: 'rancho-park', name: 'Rancho Park' },
    { id: 'reseda', name: 'Reseda' },
    { id: 'san-pedro', name: 'San Pedro' },
    { id: 'sawtelle', name: 'Sawtelle' },
    { id: 'shadow-hills', name: 'Shadow Hills' },
    { id: 'sherman-oaks', name: 'Sherman Oaks' },
    { id: 'silver-lake', name: 'Silver Lake' },
    { id: 'south-park', name: 'South Park' },
    { id: 'studio-city', name: 'Studio City' },
    { id: 'sunland', name: 'Sunland' },
    { id: 'sun-valley', name: 'Sun Valley' },
    { id: 'sylmar', name: 'Sylmar' },
    { id: 'tarzana', name: 'Tarzana' },
    { id: 'toluca-lake', name: 'Toluca Lake' },
    { id: 'tujunga', name: 'Tujunga' },
    { id: 'university-park', name: 'University Park' },
    { id: 'valley-glen', name: 'Valley Glen' },
    { id: 'valley-village', name: 'Valley Village' },
    { id: 'van-nuys', name: 'Van Nuys' },
    { id: 'venice', name: 'Venice' },
    { id: 'vermont-knolls', name: 'Vermont Knolls' },
    { id: 'vermont-slauson', name: 'Vermont-Slauson' },
    { id: 'vermont-square', name: 'Vermont Square' },
    { id: 'vermont-vista', name: 'Vermont Vista' },
    { id: 'watts', name: 'Watts' },
    { id: 'west-adams', name: 'West Adams' },
    { id: 'westchester', name: 'Westchester' },
    { id: 'west-hills', name: 'West Hills' },
    { id: 'westlake', name: 'Westlake' },
    { id: 'west-los-angeles', name: 'West Los Angeles' },
    { id: 'westwood', name: 'Westwood' },
    { id: 'wilmington', name: 'Wilmington' },
    { id: 'windsor-square', name: 'Windsor Square' },
    { id: 'winnetka', name: 'Winnetka' },
    { id: 'woodland-hills', name: 'Woodland Hills' }];

exports.NEIGHBORHOODS = NEIGHBORHOODS;