import React from 'react';
import logo  from '../asset/logo-round.svg';
import '../styles/Header.css';

export default function Header() {
    return (
        <header className="header">
            <div className="headerContent">
                <img src={logo} alt="logo" />
                <span> CROSSTOWN</span>
            </div>
        </header>
    );
}