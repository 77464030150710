import React from 'react';
import {HashRouter, Route, Switch } from 'react-router-dom';
import Signup from "./components/Signup";
import Management from "./components/Management";
import Unsubscribe from "./components/Unsubscribe";

export default function App() {
    return (
        <HashRouter basename="/">
            <Switch>
                <Route path="/" component={Signup} exact />
                <Route path="/management" component={Management} />
                <Route path="/unsubscribe" component={Unsubscribe} />
            </Switch>
        </HashRouter>
    )
}
