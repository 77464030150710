import React from 'react';
export default function PrivacyPolicy() {
    return (
        <div>
            <p>Crosstown LA, based at the USC Annenberg School for Journalism</p>
            <p>&nbsp;</p>
            <p>
                <strong>Privacy Policy</strong>
            </p>
            <p>
                This Privacy Policy (“Policy”) describes how the University of Southern
                California (“USC,” “we,” “us,” “our”) may collect, use, share, and otherwise
                process personal information we collect through the USC Annenberg Crosstown
                Survey (“Site” or “newsletters”). The Site is intended for use by persons
                located in the United States, but can be used by those outside the U.S. as
                well.
            </p>
            <p>
                <strong>I. Personal Information We Collect</strong>
            </p>
            <p>
                Personal information is any kind of information that allows us to identify
                you, directly or indirectly, such as your name, contact details, or address.
                We may collect and process the following personal information about you:
            </p>
            <ul>
                <li>
                Identifiers, such as your name, address, email address, username, and
                telephone numbers;
                </li>
            </ul>
            <p>
                We may also collect, use, and share de-identified information such as
                statistical or demographic data. This information could be derived from your
                personal information but will not reveal your identity directly or
                indirectly and, for this reason, may be used for a purpose that the
                University determines is reasonable. For example, we may aggregate usage
                information to calculate the percentage of users accessing a specific Site
                feature. However, if we combine or connect information with your personal
                information so that it can identify you directly or indirectly, we will
                treat the combined information as personal information, which will be used
                only as allowed by this Policy.
            </p>
            <p>
                <strong>II. How We Collect Your Personal Information</strong>
            </p>
            <p>We collect personal information from you via the following:</p>
            <p>
                <strong>a) Personal Information You Provide to Us</strong>
            </p>
            <ul>
                <li>
                Forms or surveys you complete (or which are completed by your authorized
                representatives on your behalf) and other information you enter directly
                in the Site; and
                </li>
                <li>
                Your correspondence and interactions with us, including by letter, email,
                and telephone.
                </li>
            </ul>
            <p>We use the following categories of cookies on the Site:</p>
            <ul>
                <li>
                <strong>Performance, functionality, and customization cookies:</strong>
                These cookies allow us to count visits and traffic sources and see how
                visitors move around the Site. They also enable the Site to provide
                enhanced functionality and personalization. This information is used only
                for internal purposes in managing the Site and improving its
                functionality. If you do not allow these cookies, we will not be able to
                monitor the performance of the Site, and some of its services may not
                function properly. We use the following performance, functionality, and
                customization cookies: a user’s language preferences such as English,
                Spanish, etc.
                </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
                <li>
                <strong>Analytics cookies:</strong>These cookies collect information that
                helps us understand how you and other visitors access the Site, how much
                time you spend there, and which pages you visit. We use this information
                to improve our services, and to customize the Site and our services for
                you. The functionality of the Site is not impacted by the rejection of
                such cookies. We may use third party analytics providers such as Google
                Analytics to collect and analyze usage of the Site. We use the following
                analytics cookies: Google Analytics. You can prevent Google Analytics from
                using your information by downloading and installing the Google Analytics
                Opt-out Browser Add-on&nbsp;
                <a href="https://tools.google.com/dlpage/gaoptout">
                    https://tools.google.com/dlpage/gaoptout
                </a>
                </li>
            </ul>
            <p>
                <strong>b) Cookie management</strong>
            </p>
            <p>
                Most web browsers automatically accept cookies. You can, however, change
                your browser to prevent this or to notify you each time a cookie is set. If
                you have turned off all cookies, however, you will not be able to
                authenticate or use the Site. To learn more about how to manage cookies on
                different types of browsers, you can visit:&nbsp;
                <a href="https://trojancheck.usc.edu/www.allaboutcookies.org">
                www.allaboutcookies.org
                </a>
            </p>
            <p>
                <strong>c) "Do Not Track" Browser Settings</strong>
            </p>
            <p>
                We do not currently use technology that recognizes “do not track” signals
                from your web browser.
            </p>
            <p>
                <strong>III. How We Use Your Personal Information</strong>
            </p>
            <p>
                We will use your personal information only when doing so is consistent with
                applicable law and, including in the following ways:
            </p>
            <ul>
                <li>
                To send you surveys and information about issues relating to LA
                neighborhoods;
                </li>
            </ul>
            <ul>
                <li>
                To operate and improve the Site, communicate with you, undertake planning,
                statistical analysis, diagnose and fix bugs, administer and maintain USC’s
                core records, protect USC’s rights and interests, ensure the security of
                USC’s assets, systems and networks, and prevent, detect and investigate
                fraud, unlawful or criminal activities in relation to our services;
                </li>
            </ul>
            <ul>
                <li>
                Where permitted or required by applicable laws and regulations; and&nbsp;
                </li>
            </ul>
            <ul>
                <li>
                Where necessary for the establishment, exercise, or defense of legal
                claims.
                </li>
            </ul>
            <p>
                <strong>IV. How We Protect Your Personal Information</strong>
            </p>
            <p>
                We take precautions to maintain the confidentiality, integrity, and security
                of your personal information, including the adoption of certain physical,
                electronic, and procedural safeguards and procedures designed to maintain
                and secure your personal information from inappropriate disclosure in
                accordance with applicable laws and regulations.
            </p>
            <p>
                We restrict access to personal information to those employees and agents of
                USC who need to know the information to perform their responsibilities. We
                may disclose such information to our service providers (including financial,
                technical, and professional service providers and consultants). We require
                such third-party service providers to protect the confidentiality of your
                personal information and to use the information only for purposes for which
                it is disclosed to them.
            </p>
            <p>
                <strong>V. When We&nbsp;Disclose Your Personal Information</strong>
                <br />
                We do not sell personal information or disclose personal information except
                as may be required or permitted by law, rule, or regulation. We may also
                disclose personal information to the following parties:
            </p>
            <ul>
                <li style={{ textAlign: "justify" }}>
                University employees who have a need to know the information in order to
                perform authorized duties;
                </li>
                <li style={{ textAlign: "justify" }}>
                With respect to students, to school officials with a legitimate
                educational interest;
                </li>
                <li style={{ textAlign: "justify" }}>
                A service provider to which USC has outsourced institutional services or
                functions;
                </li>
                <li style={{ textAlign: "justify" }}>
                Any governmental agency, regulatory authority or self-regulatory
                organization with jurisdiction over USC or its affiliates, if (i) USC
                determines that such disclosure is necessary or advisable pursuant to or
                in connection with any U.S. federal, state or local, or non- U.S., law,
                rule, regulation, executive order or policy, including without limitation
                the USA PATRIOT Act and (ii) such disclosure is not otherwise prohibited
                by law, rule, regulation, executive order or policy; and
                </li>
                <li style={{ textAlign: "justify" }}>
                Certain other persons to the extent authorized by you.
                </li>
            </ul>
            <p>
                In addition, we may use or disclose your personal data as we deem necessary
                or appropriate:
            </p>
            <ul>
                <li>
                under applicable law, including laws outside your country of residence;
                </li>
                <li>to comply with subpoenas and other legal processes;</li>
                <li>to pursue available remedies or limit damages we may sustain;</li>
                <li>to protect our operations or those of any of our affiliates; and</li>
                <li>
                to protect the rights, privacy, health, safety or property of us, our
                affiliates, you and others.
                </li>
            </ul>
            <p>
                On all occasions when it is necessary for us to share your personal
                information with other parties, we will require that such information only
                be used for the limited purpose for which it is shared and will require such
                third parties not to further share your information with others except to
                fulfill that limited purpose.
            </p>
            <p>
                <strong>VI. Retention of Your Personal Information</strong>
            </p>
            <p>
                We will retain your personal information only for as long as we are legally
                required or permitted to do so. When deciding how long to retain your
                personal information, we take into account our legal and regulatory
                obligations, the amount, nature, and sensitivity of the personal
                information, the potential risk of harm from unauthorized use or disclosure
                of your personal information, the purposes for which we process your
                personal information described above and whether we can achieve those
                purposes through other means. We may also retain your personal information
                to investigate or defend against potential legal claims in accordance with
                applicable limitation periods.
            </p>
            <p>
                <strong>VII. Children’s Privacy</strong>
            </p>
            <p>
                The Site is not directed toward children under the age of 13. We do not
                promote our Site to minors, and we do not intentionally collect any
                personally identifiable information from any person under 13. If we become
                aware of having collected personal information from children under the age
                of 13 without valid consent from their respective parents or guardians, we
                will delete it as soon as practicable.
            </p>
            <p>
                <strong>VIII. Changes to this Privacy Policy</strong>
            </p>
            <p>
                We may update this Privacy Policy periodically, without prior notice, so
                please review it frequently. If we decide to change our Privacy Policy, we
                will post the updated Privacy Policy in the Site, so that you are aware of
                the kinds of personal information we collect, use, share, and otherwise
                process. If we make material changes to this Privacy Policy, we will notify
                you on this website, in our Site, update the effective date above, and
                provide you with notice as required by applicable law.
            </p>
            <p>
                <strong>X. Contact Us</strong>
            </p>
            <p>
                If you have any questions or concerns about this Privacy Policy, please
                contact us at&nbsp;
                <a href="mailto:compliance@usc.edu">compliance@usc.edu</a>
            </p>
            <p>
                <audio style={{ display: "none" }} controls="controls" />
            </p>
        </div>
    )
}