import React, { useState } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import { TextField, 
         Button, 
         Link, 
         Dialog, 
         DialogTitle, 
         DialogContent, 
         DialogContentText } 
from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import img_desktop from '../asset/Hero-Desktop.svg';
import img_tablet from '../asset/Hero-Tablet.svg';
import logo from '../asset/logo-round.svg';
import neighborhoods from '../asset/neighborhoods.js';
import '../styles/Signup.css';
import SubscriptionService from "../services/subscription.service";
import PrivacyPolicy from "./PrivacyPolicy";
import ClientCaptcha from "react-client-captcha"
import "react-client-captcha/dist/index.css"

function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}

const Input = props => (
    <TextField
        error={ props.error }
        label= { props.label }
        value={ props.value }
        onChange={ props.onChangeFunc }
        helperText={ props.error ? props.errMsg : props.normalMsg}
        variant="outlined"
        style = {{width: '400px'}}
        margin="normal"
        size="small"
    />
);

const useStyles = makeStyles((theme) => ({
    paper: {
    //   position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));

export default function App () {
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);
    const [selectedNeighborhoodsErr, setSelectedNeighborhoodsErr] = useState(false);
    const [policyOpen, setPolicyOpen] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [captchaCode, setCaptchaCode] = useState("");
    const [captchaEntered, setCaptchaEntered] = useState('');
    const [captchaErr, setCaptchaErr] = useState(false);

    function handleClick(e) {
        e.preventDefault();
        if (!name.trim() || 
            !validateEmail(email.trim()) || 
            selectedNeighborhoods.length === 0 ||
            captchaEntered !== captchaCode.captchaCode) {
                if (!name.trim()) setNameErr(true);
                else setNameErr(false);
                if (!validateEmail(email.trim())) setEmailErr(true);
                else setEmailErr(false);
                if (selectedNeighborhoods.length === 0) setSelectedNeighborhoodsErr(true);
                else setSelectedNeighborhoodsErr(false);
                if (captchaEntered !== captchaCode.captchaCode){ 
                    setCaptchaErr(true);
                } else {
                    setCaptchaErr(false);
                }
        } else {
            setConfirmation(true);
            var data = {
                name: name,
                email: email,
                neighborhoods: selectedNeighborhoods
            }
            SubscriptionService.create(data)
                .then(response => {
                    console.log(response.data)
            })
            .catch(e => {
                console.log(e);
            });
        }
    }

    const handlePolicyOpen = () => {
        setPolicyOpen(true);
    };
    
    const handlePolicyClose = () => {
        setPolicyOpen(false);
    };

    const setCode = captchaCode => {
        setCaptchaCode({ captchaCode });
    }

    const classes = useStyles();
    const DialogBody = (
        <div className={classes.paper}>
          <DialogTitle id="simple-modal-title"></DialogTitle>
          <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
          >
            <PrivacyPolicy />
          </DialogContentText>
        </DialogContent>
        </div>
    );

    return (
        <div className="App">
            { !confirmation ? 
            <div className="left-form">
                <img className="logo" src={logo} alt="Logo" />
                <h2>Sign up for The Crosstown <br/>Neighborhood Newsletter </h2>
                <span>Receive data-driven stories about your neighborhood <br/> delivered weekly.</span>
                <form noValidate autoComplete="off">
                    <Input 
                        error={nameErr} 
                        label="First Name" 
                        value={ name } 
                        onChangeFunc={e=>setName(e.target.value)}
                        errMsg="Please enter a first name"
                        normalMsg=""
                    />
                    <br/>
                    <Input 
                        error={emailErr} 
                        label="Email Address"
                        value={ email } 
                        onChangeFunc={e=>setEmail(e.target.value)}
                        errMsg="Please enter a valid email address"
                        normalMsg=""
                    />
                    <Autocomplete
                        multiple
                        // id="tags-standard"
                        options={neighborhoods.NEIGHBORHOODS}
                        getOptionLabel={(option) => option.name}
                        defaultValue={[neighborhoods.NEIGHBORHOODS[22]]}
                        value={ selectedNeighborhoods }
                        onChange={
                            (e, input) => { setSelectedNeighborhoods(input);}
                        }
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            error={selectedNeighborhoodsErr}
                            variant="outlined"
                            label="Neighborhoods"
                            margin="normal"
                            size="small"
                            style = {{width: '400px'}}
                            // fullWidth
                            // helperText={selectedNeighborhoodsErr ? "Please enter at least one neighborhood" : "Select the neighborhoods you would like to hear from."}
                            helperText={selectedNeighborhoodsErr ? "Please enter at least one neighborhood" : ""}
                        />
                        )}
                    />
                    <br/>
                    <div className="captchaDiv">
                        <ClientCaptcha captchaCode={setCode}/>
                    </div>
                    <Input 
                        error={captchaErr} 
                        label="Type the characters seen in the image above"
                        value={ captchaEntered } 
                        onChangeFunc={e=>setCaptchaEntered(e.target.value)}
                        errMsg="Characters did not match the ones in the image above"
                        normalMsg=""
                    />
                    <br/>
                    <Button 
                        style = {{width: '400px'}}
                        // fullWidth
                        className="signUpButton"
                        variant="contained" 
                        color="primary"
                        onClick={handleClick}
                        disableElevation
                        >
                        Sign Up
                    </Button>
                    <p style={{fontSize: '12px'}}>By providing your email, you agree to the&nbsp;
                        <Link href="#" onClick={handlePolicyOpen}>
                        Crosstown Privacy Policy.
                        </Link>
                    </p>
                </form>
                <Dialog
                    scroll='paper'
                    maxWidth='md'
                    open={policyOpen}
                    onClose={handlePolicyClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {DialogBody}
                </Dialog>
            </div>
            : 
            <div className="left-form">
                <Button className="crosstownNav" variant="outlined" color="primary" href="https://xtown.la/"> Back to Crosstown </Button>
                <EmailIcon color="primary" fontSize="large"/>
                <h2> You've been subscribed </h2>
                <p> Look out for your welcome email. Be sure to check spam just in case! </p>
            </div>
            }
            <div className="hero-image-container">
                <picture>
                    <source srcSet={img_tablet} media="(max-width:800px)"></source>
                    <img className="hero-image" src={img_desktop} alt="signup-img"></img>
                </picture>
            </div>
        </div> 
    );
}