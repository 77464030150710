import http from "../http";

class SubscriptionService {
  create(data) {
    return http.post("/xnnAddSubscriber", data);
  }

  // return a string for the name, and a list for the neighborhoods
  get(data){
  	return http.post("/xnnGetSubscriber", data);
  }

  // a service to update neighborhoods and name
  update(data){
  	return http.post("/xnnUpdateSubscriber", data);
  }

  delete(data){
  	return http.post("/xnnDeleteSubscriber", data);
  }
}

export default new SubscriptionService();