import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import '../styles/Unsubscribe.css';
import SubscriptionService from "../services/subscription.service";
import { Button } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import Header from "./Header";

export default function Unsubscribe() {
    let query = new URLSearchParams(useLocation().search);
    let email = atob(query.get("account"));

    useEffect(() => {
        async function deleteSubscriber() {
            var data = {
                email: email
            }
            try {
                const result = await SubscriptionService.delete(data);
                console.log(result);
            } catch(e) {
                console.log(e);
            }
        }
        deleteSubscriber();
    }, [email]);

    return (
        <div>
            <Header />
            <div className="Unsubscribe">
                <h2 style={{color: "red"}}> {email} </h2>
                <h1> Thanks, you're unsubscribed. </h1>
                <p> You can always subscribe again in the future. We'll be there.</p>
                <p> In the mean time, feel free to check out our website and/or follow us on </p>
                <p> social media to get updates on Los Angeles. </p>
                <br></br>
                <Button 
                    style = {{width: '230px'}}
                    className="managementButton"
                    variant="contained" 
                    color="primary"
                    href="https://xtown.la/"
                    disableElevation
                    >
                    Explore Crosstown &ensp;<ArrowForwardIcon />
                </Button>
                <div className="socialMediaIcons">
                    <TwitterIcon color='primary' fontSize='large'/>
                    <InstagramIcon color='primary' fontSize='large'/>
                    <FacebookIcon color='primary' fontSize='large'/>
                </div>
            </div>
        </div>
    )
}